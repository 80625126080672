// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx": () => import("./../../../node_modules/@narative/gatsby-theme-novela/src/templates/author.template.tsx" /* webpackChunkName: "component---node-modules-narative-gatsby-theme-novela-src-templates-author-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-now-tsx": () => import("./../../../src/pages/now.tsx" /* webpackChunkName: "component---src-pages-now-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

